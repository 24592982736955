import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import get from 'lodash/get';

import getCTAProps from '@utils/cta';
import { useIntl, useLocalFormat } from '@utils/localize';
import Button from '@components/Button';
import { ChevronDown } from '@components/Icon';
import Image from '@components/Image';
import { RoughOne, Swirl } from '@components/vector';
import ReactPlayer from 'react-player';

import * as styled from './styles';

function Hero({
  background,
  breadcrumb,
  button,
  description,
  category,
  publishedDate,
  updatedDate,
  heading,
  headingAlignment,
  imagesExtended = [],
  imagesFlipped,
  colorTheme,
  eventData,
}) {
  const intl = useIntl();
  const [isCover, setIsCover] = useState(true);
  const localFormat = useLocalFormat();
  const themeStyles = styled.getThemeStyles(colorTheme);

  let _headingAlignment = headingAlignment;
  if (!imagesExtended.length) {
    _headingAlignment = 'center';
  }

  if (imagesExtended[1]) {
    imagesExtended[1].image.asset.fluid.sizes = '(max-width: 600px) 40vw, 20vw';
  }

  if (!button && eventData?.cta) {
    button = eventData.cta
  }

  const showVideo = () => {
    setIsCover(false);
  }

  return (
    <styled.Wrapper
      flipped={_headingAlignment === 'right'}
      noImages={!imagesExtended.length}
      colorTheme={colorTheme}
    >
      <styled.BlobWrapper>
        <styled.Blob
          align={_headingAlignment === 'right' ? 'left' : 'right'}
          colorTheme={colorTheme}
          type={background.type}
        >
          {background.edge && <RoughOne />}
        </styled.Blob>
      </styled.BlobWrapper>
      <styled.Inner
        flipped={_headingAlignment === 'right'}
        noImages={!imagesExtended.length}
        colorTheme={colorTheme}
      >
        <styled.Content
          flipped={_headingAlignment === 'right'}
          align={_headingAlignment}
          hasCTA={get(button, 'label')}
        >
          {breadcrumb && (
            <styled.Breadcrumb align={_headingAlignment} sizes={['small', 'regular']} colorTheme={colorTheme}>
              {breadcrumb.grandparent && (
                <>
                  <Link to={breadcrumb.grandparent.slug.current}>
                    {breadcrumb.grandparent.title}
                  </Link>
                  <ChevronDown />
                </>
              )}
              {breadcrumb.sanityParent && (
                <>
                  <Link to={breadcrumb.sanityParent.slug.current}>
                    {breadcrumb.sanityParent.title}
                  </Link>
                  <ChevronDown />
                </>
              )}
              {breadcrumb.self}
            </styled.Breadcrumb>
          )}
          {publishedDate && (
            <styled.Caption sizes={['small', 'regular']} colorTheme={colorTheme}>
              {category && (
                <span onClick={() => selectCategory(category.title)}>
                  {category.title}
                </span>
              )}
              {publishedDate && localFormat(new Date(publishedDate), 'LLL d, yyyy')}
              {new Date(updatedDate) > new Date('2020-08-01') && updatedDate && (
                <styled.UpdatedDate> — {intl.formatMessage({ id: 'updated' })} {localFormat(new Date(updatedDate), 'LLL d, yyyy')}</styled.UpdatedDate>
              )}
            </styled.Caption>
          )}
          <styled.Heading level={100} colorTheme={colorTheme}>
            {heading}
          </styled.Heading>
          <styled.Description sizes={['small', 'large']}>
            {description}
          </styled.Description>
          {eventData && (<styled.EventData sizes={['small', 'large']}>
            <div>Location: {eventData.location}</div>
            <div>Date: {eventData.date}</div>
            <div>Time: {eventData.time}</div>
          </styled.EventData>)}
          {get(button, 'label') && (
            <Button
              appearance={eventData?.cta.theme || (colorTheme === 'yellow' ? 'blue' : colorTheme)}
              fill
              isCentered={!!eventData?.cta}
              {...getCTAProps(button)}
            >
              {button.label}
            </Button>
          )}
        </styled.Content>
        {imagesExtended.length > 0 && (
          <styled.Images flipped={imagesFlipped || imagesExtended.length === 1}>
            {imagesExtended[0] && isCover ? (
              <styled.ImageWrapper
                flipped={imagesFlipped || imagesExtended.length === 1}
                onClick={imagesExtended[0].videoUrl && showVideo}
                data-has-video={!!imagesExtended[0].videoUrl}
              >
                <Image
                  fluid={get(imagesExtended[0], 'image.asset.fluid')}
                  edge={2}
                  ratio={3 / 4}
                  {...imagesExtended[0]}
                />
                {imagesExtended[0].credit && (
                  <span>({imagesExtended[0].credit})</span>
                )}
              </styled.ImageWrapper>
              ) : (
              <ReactPlayer
                url={imagesExtended[0].videoUrl}
                width="100%"
                height="560px"
                controls={true}
              />
            )}
            {imagesExtended[1] && (
              <styled.ImageWrapper>
                <Image fluid={get(imagesExtended[1], 'image.asset.fluid')} ratio={3 / 4} {...imagesExtended[1]} />
                {imagesExtended[1].credit && (
                  <span>({imagesExtended[1].credit})</span>
                )}
              </styled.ImageWrapper>
            )}
          </styled.Images>
        )}
      </styled.Inner>
      <Swirl color={themeStyles.fill} />
    </styled.Wrapper>
  );
}

Hero.propTypes = {
  background: PropTypes.shape({
    edge: PropTypes.bool,
    type: PropTypes.oneOf(['full', 'semi']),
  }),
  breadcrumb: PropTypes.object,
  button: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  }),
  description: PropTypes.string,
  category: PropTypes.object,
  publishedDate: PropTypes.instanceOf(Date),
  updatedDate: PropTypes.instanceOf(Date),
  heading: PropTypes.string.isRequired,
  headingAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  imagesExtended: PropTypes.arrayOf(PropTypes.object),
  imagesFlipped: PropTypes.bool,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

Hero.defaultProps = {
  background: {
    type: 'semi',
  },
  headingAlignment: 'left',
  colorTheme: 'blue',
};

export default Hero;
